import { defineStore } from 'pinia';

export const useProgShareStore = defineStore('programmeShareStore', () => {
    var progShareModal = ref<boolean>(false);
    var subProgrammeId = ref<string>("");
    var subProgrammeName = ref<string>("");

    const getProgShareModal = computed(
        () => {
            return progShareModal.value;
        }
    );

    function isOpenProgShareModal() {
        progShareModal.value = true;
    }
    function isClosedProgShareModal() {
        progShareModal.value = false;
    }

    function setSubProgrammeId(name: string) {
        subProgrammeId.value = name;
    }

    function setSubProgrammeName(subName: string) {
        subProgrammeName.value = subName;
    }

    return {
        getProgShareModal,
        isOpenProgShareModal,
        isClosedProgShareModal,
        getSubProgrammeId: computed(() => subProgrammeId.value),
        getSubProgrammeName: computed(() => subProgrammeName.value),
        setSubProgrammeId,
        setSubProgrammeName
    }
});